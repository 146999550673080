<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>ID Klasifikasi</label>
            <InputText v-model="item.klasifikasi_id" required="true" autofocus :class="{ 'p-invalid': errorEdit.klasifikasi_id_new }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.klasifikasi_id_new" >{{ errorEdit.klasifikasi_id_new[0] }}</small >
        </div>
         <div class="field">
            <label>Nama Klasifikasi</label>
            <InputText v-model="item.klasifikasi_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.klasifikasi_name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.klasifikasi_name" >{{ errorEdit.klasifikasi_name[0] }}</small >
        </div>
         <div class="field">
            <label>Status</label>
            <Dropdown v-model="item.is_active" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Pilih Status" :class="{ 'p-invalid': errorEdit.status }"/>
                <small class="p-invalid" style="color: red" v-if="errorEdit.status" >{{ errorEdit.status[0] }}</small>
        </div>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownStatus: [
                {name: 'AKTIF', code: 'y'},
                {name: 'TIDAK AKTIF', code: 'n'},
            ],
            klasifikasi_id_old: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.klasifikasi_id_old = this.item.klasifikasi_id;
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-customer-klasifikasi/update',
                data: {
                    "klasifikasi_id_new": this.item.klasifikasi_id,
                    "klasifikasi_id_old": this.klasifikasi_id_old,
                    "klasifikasi_name": this.item.klasifikasi_name,
                    "status" : this.item.is_active,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diupdate', life: 3000,});
            });
		},
    }
}
</script>